<template>
  <div class="property">
    <!-- 顶部区域 -->
    <div class="me-top">
      <div class="top-back" @click="goMyIndex">
        <img src="../../assets/images/goback.png" alt="" />
      </div>
      <div class="top-content flex-center">
        <div class="top-box flex-center">
          <div class="top-portrait">
            <img :src="user.avatarUrl" alt="" />
          </div>
          <div class="top-msg">
            <div class="top-name">{{ user.realName }}</div>
            <div class="top-phone flex-center">
              <img src="../../assets/images/me-sj.png" alt="" />
              <span>{{ user.mobile | phoneFilter }}</span>
            </div>
            <div class="top-company">{{ mchInfo.mchName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="property-box">
      <div class="property-item today" v-if="userPermission == 1 || housePermission == 1">
        <div class="property-title">今日待办</div>
        <van-grid :column-num="3" :border="false">
          <van-grid-item v-for="item in todayList" v-if="item.show == 1" :key="item.id" :icon="item.imgUrl" :text="item.text" :badge="item.badge" :to="item.path" />
        </van-grid>
      </div>
      <div class="property-item customer" v-if="userPermission == 1">
        <div class="property-title">客户跟进</div>
        <van-grid :column-num="3" :border="false">
          <van-grid-item v-for="item in customerList" :key="item.id" :icon="item.imgUrl" :text="item.text" :badge="item.badge" :to="item.path" />
        </van-grid>
      </div>
      <div class="property-item house" v-if="housePermission == 1">
        <div class="property-title">房源收件</div>
        <van-grid :column-num="3" :border="false">
          <van-grid-item v-for="item in houseList" :key="item.id" :icon="item.imgUrl" :text="item.text" :badge="item.badge" :to="item.path" />
        </van-grid>
      </div>
      <div class="property-item qrCode">
        <div class="property-title">二维码</div>
        <van-grid :column-num="3" :border="false">
          <van-grid-item v-for="item in qrCode" :key="item.id" :icon="item.imgUrl" :text="item.text" :badge="item.badge" :to="item.path" />
        </van-grid>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "property",
  data() {
    return {
      user: {},
      mchInfo: {},
      todayList: [
        {
          id: 0,
          text: "待带看房",
          imgUrl: require("../../assets/images/power02.png"),
          badge: "",
          path: "/my/followUp",
          show: 0,
        },
        {
          id: 1,
          text: "房源收件",
          imgUrl: require("../../assets/images/power03.png"),
          badge: "",
          path: "/my/listingReceipt",
          show: 0,
        },
      ],
      customerList: [
        
        {
          id: 1,
          text: "我的跟进",
          imgUrl: require("../../assets/images/power04.png"),
          path: "/my/followUp",
        },
        {
          id: 2,
          text: "我的资源",
          imgUrl: require("../../assets/images/power05.png"),
          path: "/my/myResource",
        },
      ],
      houseList: [
        {
          id: 0,
          text: "房源收件",
          imgUrl: require("../../assets/images/power06.png"),
          path: "/my/listingReceipt",
        },
      ],
      qrCode :[
        {
          id: 0,
          text: "我的二维码",
          imgUrl: require("../../assets/images/power01.png"),
          path: "/my/barcode",
        },
      ],
      userPermission: 0,
      housePermission: 0,
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    goMyIndex() {
      this.$router.push("/my/index");
    },
    getUserInfo() {
      var page = this;
      this.$post("user/getUserInfo", { type: 1 }).then((res) => {
        if (res.status == 200) {
          this.user = res.data.userInfo;
          if (this.user.identity == 0) {
            var router = this.$toast.success({
              message: "你不是物业人员，请先绑定物业人员账号",
              duration: 2000,
              onClose: function () {
                page.$router.push("/me/revise");
              },
            });
            return false;
          }
          if (!res.data.userInfo.realName) {
            this.user.realName = res.data.userInfo.nickName;
          }
          //-------------物业机构信息--------------
          var mchInfo = res.data.mchInfo;
          //房源管理权限
          this.housePermission = mchInfo.housePermission;
          this.todayList[0].show = mchInfo.housePermission;
          if (mchInfo.houseCount > 0) {
            this.todayList[0].badge = mchInfo.houseCount;
          }
          //用户权限
          this.userPermission = mchInfo.userPermission;
          this.todayList[1].show = mchInfo.userPermission;
          if (mchInfo.userCount > 0) {
            this.todayList[1].badge = mchInfo.userCount;
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.property {
  background-color: #fff;
  min-height: 100vh;
  box-sizing: border-box;
  .me-top {
    width: 100%;
    height: 150px;
    background: url(../../assets/images/me-bg.png) no-repeat;
    background-size: 100% 100%;
    position: relative;

    box-sizing: border-box;
    .top-back {
      line-height: 1;
      padding: 10px;
      img {
        width: 20px;
        height: 20px;
      }
    }

    .top-content {
      justify-content: space-between;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      .top-box {
        .top-portrait {
          width: 76px;
          height: 76px;
          border-radius: 50%;
          border: 3px solid rgba(255, 255, 255, 0.3);
          box-sizing: border-box;
          margin-right: 10px;
          img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
          }
        }
        .top-msg {
          height: 70px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          div {
            line-height: 1;
          }
          .top-name {
            color: #fff;
            font-size: 18px;
          }
          .top-phone {
            img {
              width: 13px;
              height: 13px;
              margin-right: 5px;
            }
            span {
              color: #fff;
              font-size: 13px;
              line-height: 1;
            }
          }
          .top-company {
            color: #fff;
            font-size: 13px;
          }
        }
      }
    }
  }
  .property-box {
    .property-item {
      .property-title {
        color: #535353;
        font-size: 15px;
        padding-left: 20px;
        line-height: 1;
        margin-top: 20px;
        position: relative;
        &::after {
          content: "";
          width: 2px;
          height: 12px;
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          background: #00d3c4;
        }
      }
      /deep/.van-grid-item__content {
        padding: 25px 8px;
      }
      /deep/.van-grid-item__icon {
        font-size: 20px;
      }

      /deep/.van-info {
        top: 5px;
        right: -5px;
      }
      /deep/.van-grid-item__text {
        font-size: 15px;
        color: #555;
      }
    }
  }
}
</style>